// Libraries
import styled from "styled-components";
import { Link } from "react-router-dom";

function LinkBox(props) {
	const StyledContainer = styled.div`
		width: 100%;
		height: 100%;
	`;
	const StyledPicture = styled.div`
		background: url(${props.backgroundImage});
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 80%;
		width: 100%;
	`;
	const StyledBox = styled.div`
		width: 100%;
		height: 20%;
		background-color: black;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		font-size: 1.4rem;
		font-weight: 600;
	`;

	return (
		<Link
			to={`${props.link}`}
			className={props.className}
			style={{ textDecoration: "none" }}>
			<StyledContainer>
				<StyledPicture />
				<StyledBox>{props.name}</StyledBox>
			</StyledContainer>
		</Link>
	);
}
export default LinkBox;
