// Libraries
import styled from "styled-components";

function Boat(props) {
	const StyledContainer = styled.div`
		width: 100%;
		height: 100%;
		display: flex;
		max-width: 60rem;
		margin: 0 auto;
	`;
	const StyledPicture = styled.div`
		background: url(${props.backgroundImage});
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		height: 100%;
		width: 100%;
		min-width: 15rem;
	`;

	return (
		<StyledContainer className={props.className}>
			<StyledPicture />
			<div>
				<h3>{props.name}</h3>
				<p>{props.description}</p>
				<p>{props.rating}</p>
				<p>{props.phrf}</p>
			</div>
		</StyledContainer>
	);
}
export default Boat;
