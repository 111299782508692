// Libraries
import styled from "styled-components";
import { Link } from "react-router-dom";

//Components
import Footer from "../Components/Footer";
import Header from "../Components/Header";

export default function Contact() {
	const StyledContainer = styled.div`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
	`;

	const StyledTxtCont = styled.div`
		max-width: 800px;
	`;

	//Table Settings
	const StyledPosition = styled.td`
		text-align: left;
		font-weight: 700;
	`;
	const StyledName = styled.td`
		text-align: right;
	`;
	const StyledRow = styled.tr`
		height: 3rem;
	`;

	return (
		<>
			<Header />
			<div className="grid">
				<StyledContainer className="card-wide">
					<h1>Contact Information</h1>
					<p>
						<b>Office Hours:</b> By Appointment To schedule an appointment
						<br></br>
						<b>Email:</b>&nbsp;
						<a href="mailto:programdirector@washingtonyachtclub.org">
							programdirector@washingtonyachtclub.org
						</a>
						<br></br>
						<b>Office Location:</b>
						<a href="uw.edu/maps/?hub" target="_blank" rel="noreferrer">
							HUB
						</a>
						&nbsp;Sub-basement 259 <br></br>
						<b>Office Phone:</b> (425) 298-5670 (voicemail only)<br></br>
						<br></br>
						<b>Mailing Address:</b> Washington Yacht Club University of
						Washington SAO 235 Box 352238 Seattle, WA 98195-2238<br></br>
						Feel free to contact us with questions or feedback at&nbsp;
						<a href="mailto: sailing@uw.edu">sailing@uw.edu</a>
						&nbsp;or talk to us directly by joining our&nbsp;
						<a
							href="https://discord.gg/5uGyJvtXM6"
							rel="noreferrer"
							target="blank">
							discord
						</a>
						.
					</p>
				</StyledContainer>

				<StyledContainer className="card-wide">
					<h1>Support the Club</h1>
					<StyledTxtCont>
						We would like to thank our wonderful sponsors that help make
						the Washington Yacht Club a great organization: <br></br>
						<br></br>INSERT TIDES MARINE PICTURE HERE<br></br>
						<br></br>
						The Washington Yacht Club is a 501(c)(3) charitable
						organization eligible to receive tax-deductible contributions
						in accordance with section 170 of the Internal Revenue Code.
						<br></br>
						Please contact our&nbsp;
						<a href="mailto:commodore@washingtonyachtclub.org">
							Commodore
						</a>
						&nbsp;if you are interested in making a donation.
					</StyledTxtCont>
				</StyledContainer>
				<StyledContainer className="card-small">
					<h2>General wish list:</h2>
					<ul>
						<li>Boats (Dinghies, Catamarans, Keelboats, etc.)</li>
						<li>Boat parts (Sails, Blocks, Cleats, etc.)</li>
						<li>Guest instruction and lectures</li>
						<li>Financial Support</li>
						<li>Office Supplies</li>
						<li>Power Tools</li>
					</ul>
				</StyledContainer>
				<StyledContainer className="card-small">
					<h2>Specific wish list:</h2>
					<ul>
						<li>No 1 Genoa for San Juan 30</li>
						<li>5-10 hp outboard (long shaft) for keelboat</li>
						<li>60 HP outboard for rescue whaler</li>
						<li>~22 ft daysailor</li>
						<li>Laser, 5o5 dinghies, H16 catamaran</li>
						<li>Cruising keelboat to update current keelboat fleet</li>
					</ul>
				</StyledContainer>
				<StyledContainer className="card-wide">
					<h1>Club Officers and Committee Members</h1>
					<StyledTxtCont>
						This is a list of the current Club Officers and Committee
						members. Click on a members name to contact them <br></br>
						<br></br>*We currently have a number of vacancies. Please
						email&nbsp;<a href="mailto:sailing@uw.edu">sailing@uw.edu</a>
						&nbsp;if you would like to volunteer!*
						<br></br>
						<br></br>If you want to learn more about the positions, visit
						the&nbsp;<Link to="/join">how to get involved page</Link>. If
						you are interested in applying for a vacant position, you can
						contact the&nbsp;
						<a href="mailto:commodore@washingtonyachtclub.org">
							Commodore
						</a>
						, or&nbsp;
						<a href="mailto:programdirector@washingtonyachtclub.org">
							Program Director
						</a>
						.
					</StyledTxtCont>
				</StyledContainer>

				<StyledContainer className="card-small">
					<h2>Positions</h2>
					<table>
						<StyledRow>
							<StyledPosition> Program Director: </StyledPosition>
							<StyledName>
								<a href="mailto:programdirector@washingtonyachtclub.org">
									Daniel Oliver
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Purser: </StyledPosition>
							<StyledName>
								<a href="mailto:purser@washingtonyachtclub.org">
									John Redlingshafer
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Quartermaster: </StyledPosition>
							<StyledName>
								<a href="mailto:quartermaster@washingtonyachtclub.org">
									Ken Howe
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Snooze'N'Cruise Chair: </StyledPosition>
							<StyledName>
								<a href="mailto:snc@washingtonyachtclub.org">
									Tom Schaefer
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition>
								Snooze'N'Cruise Food Chair:
							</StyledPosition>
							<StyledName>
								<a href="mailto:sncfood@washingtonyachtclub.org">
									Joanne Ho
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Media Director: </StyledPosition>
							<StyledName>
								<a href="mailto:media@washingtonyachtclub.org">
									Michael Stickels
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Recruitment Chair: </StyledPosition>
							<StyledName>
								<a href="mailto:publicity@washingtonyachtclub.org">
									[Vacant]
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Social Chair: </StyledPosition>
							<StyledName>
								<a href="mailto:social@washingtonyachtclub.org">
									Sam Lindsay
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Webmaster: </StyledPosition>
							<StyledName>
								<a href="mailto:webmaster@washingtonyachtclub.org">
									Cato Cannizzo
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> ASUW Senate Rep: </StyledPosition>
							<StyledName>
								<a href="mailto:ASUW@washingtonyachtclub.org">
									[Vacant]
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Faculty Advisor: </StyledPosition>
							<StyledName>
								<a href="mailto:facultyadvisor@washingtonyachtclub.org">
									John Pyles
								</a>
								, &<br></br>
								<a href="mailto:facultyadvisor@washingtonyachtclub.org">
									Leo Morales
								</a>
							</StyledName>
						</StyledRow>
					</table>
				</StyledContainer>
				<StyledContainer className="card-small">
					<h2>Fleet Captains</h2>
					<table>
						<StyledRow>
							<StyledPosition>Head Fleet Captain: </StyledPosition>
							<StyledName>
								<a href="mailto:headfleetcaptain@washingtonyachtclub.org">
									Ted Cohen
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition>Bravo Fleet Captain: </StyledPosition>
							<StyledName>
								<a href="mailto:bravofleet@washingtonyachtclub.org">
									[Vacant]
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition>Laser Fleet Captain: </StyledPosition>
							<StyledName>
								<a href="mailto:laserfleet@washingtonyachtclub.org">
									Andreas Freund
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition>
								Double-Handed Fleet Captain:
							</StyledPosition>
							<StyledName>
								<a href="mailto:doublehandedfleet@washingtonyachtclub.org">
									Grant Shogren
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition>Performance Fleet Captain:</StyledPosition>
							<StyledName>
								<a href="mailto:performancefleet@washingtonyachtclub.org">
									[Vacant]
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Keelboat Fleet Captain: </StyledPosition>
							<StyledName>
								<a href="mailto:keelboatfleet@washingtonyachtclub.org">
									[Vacant]
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Catamaran Fleet Captain: </StyledPosition>
							<StyledName>
								<a href="mailto:catamaranfleet@washingtonyachtclub.org">
									Tom Nguyen
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Sailboard Fleet Captain: </StyledPosition>
							<StyledName>
								<a href="mailto:sailboardfleet@washingtonyachtclub.org">
									Wm Salt Hale
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Daysailer Fleet Captain: </StyledPosition>
							<StyledName>
								<a href="mailto:daysailorfleet@washingtonyachtclub.org">
									Cylas Belknap
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Whaler Fleet Captain: </StyledPosition>
							<StyledName>
								<a href="mailto:whalerfleet@washingtonyachtclub.org">
									Angie Cuadra-Palacios
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Sail Fleet Captain: </StyledPosition>
							<StyledName>
								<a href="mailto:sailfleet@washingtonyachtclub.org">
									Brendan Shannon
								</a>
								, &<br></br>
								<a href="mailto:sailfleet@washingtonyachtclub.org">
									Leo Morales
								</a>
							</StyledName>
						</StyledRow>
					</table>
				</StyledContainer>

				<StyledContainer className="card-small">
					<h2>Committees</h2>
					<table>
						<StyledRow>
							<StyledPosition>Fleet Planning Committee:</StyledPosition>
							<StyledName>TBA...</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Whaler Committee: </StyledPosition>
							<StyledName> TBA...</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Diversity Committee: </StyledPosition>
							<StyledName>TBA...</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition>
								Ithaka Operations Committee:
							</StyledPosition>
							<StyledName>TBA...</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition>
								Commodore's Ball Committee:
							</StyledPosition>
							<StyledName>TBA...</StyledName>
						</StyledRow>
					</table>
				</StyledContainer>
				<StyledContainer className="card-small">
					<h2>Examiners</h2>
					<table>
						<StyledRow>
							<StyledPosition> Ratings Examiner:</StyledPosition>
							<StyledName>
								<a href="mailto:ratings@washingtonyachtclub.org">
									Andrew Cheung
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition>Ratings Examiner: </StyledPosition>
							<StyledName>
								<a href="mailto:ratings@washingtonyachtclub.org">
									Theodore Cohen
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition>Ratings Examiner: </StyledPosition>
							<StyledName>
								<a href="mailto:ratings@washingtonyachtclub.org">
									Carlos Gonalez
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition>Ratings Examiner:</StyledPosition>
							<StyledName>
								<a href="mailto:ratings@washingtonyachtclub.org">
									Tanner Graves
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition>Ratings Examiner:</StyledPosition>
							<StyledName>
								<a href="mailto:ratings@washingtonyachtclub.org">
									Tom Nguyen
								</a>
							</StyledName>
						</StyledRow>
					</table>
				</StyledContainer>
				<StyledContainer className="card-wide">
					<h2>Officers</h2>
					<table>
						<StyledRow>
							<StyledPosition> Co-Commodores: </StyledPosition>
							<StyledName>
								<a href="mailto:commodore@washingtonyachtclub.org">
									Anthony Au
								</a>
								, &<br></br>
								<a href="mailto:commodore@washingtonyachtclub.org">
									Peter Correa
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Vice Commodore: </StyledPosition>
							<StyledName>
								<a href="mailto:rearcommodore@washingtonyachtclub.org">
									William Rae
								</a>
							</StyledName>
						</StyledRow>
						<StyledRow>
							<StyledPosition> Rear Commodores: </StyledPosition>
							<StyledName>
								<a href="mailto:commodore@washingtonyachtclub.org">
									Wm Salt Hale
								</a>
								, &<br></br>
								<a href="mailto:commodore@washingtonyachtclub.org">
									Dylan Wesen
								</a>
							</StyledName>
						</StyledRow>
					</table>
				</StyledContainer>
				<StyledContainer className="card-wide">
					<h1>Email Lists</h1>
					<StyledTxtCont>
						Stay up-to-date with all the latest announcements, activities
						and schedule by joining our email lists.
						<br></br>
						<br></br>The club maintains several email lists to keep
						members informed. Read the purpose each of the mailing list
						serve and choose the one you are interested and signup. All
						the lists maintained are mailman list.
					</StyledTxtCont>
				</StyledContainer>
				<StyledContainer>
					<h2>WYC Info: Official Club Announcements</h2>
					<StyledTxtCont>
						Official club announcements are posted on this list. This is
						the option for those who do not have time or desire to
						participate in the chatter on the wyc list.
						<br></br>
						<br></br>
						Note: If you are on the wyc@u.washington.edu or
						sailing@u.washington.edu lists you do not need to subscribe to
						wyc_info, as these two lists automatically receive all
						messages posted to wyc_info.
						<br></br>
						<br></br>
						<a
							href="http://mailman.u.washington.edu/mailman/listinfo/wyc_info"
							target="_blank"
							rel="noreferrer">
							<button>Subscribe/Unsubscribe here</button>
						</a>
					</StyledTxtCont>
				</StyledContainer>
				<StyledContainer>
					<h2>WYC: General Chat List</h2>
					<StyledTxtCont>
						This is our general chat list. Discussion should be related to
						boats and sailing. This is where impromptu sailing trips are
						planned, discussions about racing technique, what dry suit is
						the best for the $$ etc.
						<br></br>
						<br></br>
						Note: This list also receives all email sent to
						wyc_info@u.washington.edu.
						<br></br>
						<br></br>
						<a
							href="http://mailman.u.washington.edu/mailman/listinfo/wyc"
							target="_blank"
							rel="noreferrer">
							<button>Subscribe/Unsubscribe here</button>
						</a>
					</StyledTxtCont>
				</StyledContainer>
				<StyledContainer>
					<h2>KB: Keelboat Related Discussion</h2>
					<StyledTxtCont>
						This list is for the discussion of keelboat related topics,
						such as upcoming cruises, keelboat work parties, what new
						keelboat we should buy, who did not empty the portapottie,
						etc.
						<br></br>
						<br></br>
						<a
							href="http://mailman.u.washington.edu/mailman/listinfo/keelboat"
							target="_blank"
							rel="noreferrer">
							<button>Subscribe/Unsubscribe here</button>
						</a>
					</StyledTxtCont>
				</StyledContainer>
				<StyledContainer>
					<h2>WYC Exec: Business Discussion</h2>
					<StyledTxtCont>
						This is a list to discuss business issues the will be brought
						up at club business meetings, and provide an opportunity for
						members to toss ideas about policy and other dry topics around
						prior to going to a meeting for a vote. Club members who want
						to participate in the organization and future direction of the
						club can subscribe. Note: Current WYC Club Members Only
						<br></br>
						<br></br>
						<a
							href="http://mailman.u.washington.edu/mailman/listinfo/wyc_exec"
							target="_blank"
							rel="noreferrer">
							<button>Subscribe/Unsubscribe here</button>
						</a>
					</StyledTxtCont>
				</StyledContainer>
			</div>
			<Footer />
		</>
	);
}
