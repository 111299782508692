// Libraries
import styled from "styled-components";
//Components
import Footer from "../Components/Footer";
import Header from "../Components/Header";

//Bunch of stuff commented out for the weather
// const StyledIframe = styled.iframe`
// 	width: 100%;
// 	aspect-ratio: 4/3;
// `;
// <h2>Current Weather and Wind</h2>
// <StyledContainer className="card-med card-tall">
// 	<StyledIframe src="http://sirocco.accuweather.com/nxssa_R1_640x480d/R1/inxR1kseaa.gif" />
// </StyledContainer>

export default function Members() {
	const StyledContainer = styled.div`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
	`;

	const StyledH2 = styled.h2`
		margin-block-start: 0em;
		margin-block-end: 0.5em;
	`;
	const StyledH3 = styled.h3`
		margin-block-start: 0.5em;
		margin-block-end: 0.5em;
	`;
	const StyledList = styled.ul`
		margin-block-start: 0em;
		margin-block-end: 0.25em;
	`;

	return (
		<>
			<Header />
			<StyledContainer>
				<h1>For Members</h1>
			</StyledContainer>
			<div className="grid grid3">
				<StyledContainer className="">
					<h2>The WYC Database</h2>
					<p>
						If you need to update your information with the club, this is
						the place to do it. Fair warning though the backend database
						is a dark place full of PHP horrors unimaginable. Open the
						database with the button below.&nbsp;
						<a
							href="http://database.washingtonyachtclub.org/"
							target="_blank"
							rel="noreferrer">
							<button>Database</button>
						</a>
						<br></br>
						<br></br>
						If you are a new member please email the&nbsp;
						<a href="mailto:programdirector@washingtonyachtclub.org">
							program director
						</a>
						&nbsp;to get a WYC number.
					</p>
				</StyledContainer>
				<StyledContainer className="">
					<h2>The WYC Store</h2>
					<p>
						If you want to support the club check out our club store! You
						can pay for your membership, buy keelboat reservations, and
						even donate your money to the club you cool person you. &nbsp;
						<a
							href="https://washington-yacht-club.square.site/"
							target="_blank"
							rel="noreferrer">
							<button>Club Store</button>
						</a>
					</p>
				</StyledContainer>
				<StyledContainer className="card-end3">
					<h2>Membership Renewal</h2>
					<p>
						If you already have a WYC card, please complete the membership
						sign-up form below to initiate or renew your membership. We do
						not recommend using this form on mobile. &nbsp;
						<a
							href="https://docs.google.com/forms/d/e/1FAIpQLSdII-oA966su8awxvmRElbNHWPND0l4Tj-k3pefW-OTbDgS1Q/viewform"
							target="_blank"
							rel="noreferrer">
							<button>Form Here</button>
						</a>
						<br></br>
						<br></br>
						If you are a new member please email the&nbsp;
						<a href="mailto:programdirector@washingtonyachtclub.org">
							program director
						</a>
						&nbsp;to get a WYC number.
						<br></br>
						<br></br>
						Note- If you are an alumni/faculty/staff you will need a
						password to complete the form. If you did not receive and
						email with the password please email the&nbsp;
						<a href="mailto:programdirector@washingtonyachtclub.org">
							program director
						</a>
						.
					</p>
				</StyledContainer>
				<StyledContainer className="card-wide3">
					<h1>Club Documents & Forms</h1>
				</StyledContainer>
				<StyledContainer className="">
					<StyledH2>Electronic Forms</StyledH2>
					<StyledList>
						<li>
							<a
								href="https://secure.rightsignature.com/templates/ca9a6446-1c16-4629-af29-c471e1e3b05f/template-signer-link/f32990ec98c95f6487b7d2e96f4f394a"
								target="_blank"
								rel="noreferrer">
								<StyledH3>Electronic Reimbursement Form</StyledH3>
							</a>
							This form is for the reimbursement of club-related
							purchases.
						</li>
						<li>
							<a
								href="http://checkout.washingtonyachtclub.org/"
								target="_blank"
								rel="noreferrer">
								<StyledH3> Boat Checkout Form</StyledH3>
							</a>
							If you are checking out a boat, please fill out this form. Also, remember to sign your boat back in!
						</li>
						<li>
							<StyledH3>
								<a
									href="https://secure.rightsignature.com/templates/0a3baffa-4957-4a45-9e19-ce191ef7849c/template-signer-link/1147a59ad6f68fd088871e2eed007117"
									target="_blank"
									rel="noreferrer">
									Affidavit and Release for Guests
								</a>
							</StyledH3>
							A new member must complete this form prior to using any
							club resources.
						</li>
						<li>
							<a
								href="https://forms.gle/8vFbZ9EFuBrDcoLh8"
								target="_blank"
								rel="noreferrer">
								<StyledH3> Damage Report Form</StyledH3>
							</a>
							If a boat becomes damaged during use, please report the
							damage here.
						</li>
					</StyledList>
				</StyledContainer>

				<StyledContainer className="">
					<StyledH2>Club Books</StyledH2>
					<StyledList>
						<li>
							<a
								href="https://docs.google.com/a/washingtonyachtclub.org/viewer?a=v&pid=sites&srcid=d2FzaGluZ3RvbnlhY2h0Y2x1Yi5vcmd8d3d3fGd4OjI1MWQ5ODc0NjQyZDY2MmI"
								target="_blank"
								rel="noreferrer">
								<StyledH3>Daysailing Handbook</StyledH3>{" "}
							</a>
							The old WYC instruction manual for dinghies.
						</li>
						<li>
							<a
								href="https://drive.google.com/file/d/14v9vaL2WgTmtHn0437lbYpVDw2r54fgX/view?usp=sharing"
								target="_blank"
								rel="noreferrer">
								<StyledH3>Cruising Under Sail</StyledH3>
							</a>
							WYC instruction manual for keelboats.
						</li>
						<li>
							<a
								href="https://drive.google.com/file/d/1-pclqOK5lTZa2uGC1eqC48dTaSCT0dkJ/view?usp=sharing"
								target="_blank"
								rel="noreferrer">
								<StyledH3>Instructor Handbook</StyledH3>
							</a>
							WYC guide for instructors.
						</li>
					</StyledList>
				</StyledContainer>
				<StyledContainer className="card-end3">
					<StyledH2>Legal Documents</StyledH2>
					<StyledList>
						<li>
							<a
								href="https://drive.google.com/file/d/1cXO5StAesbktrw44bIPGaUvWGkNN9e9Q/view?usp=sharing"
								target="_blank"
								rel="noreferrer">
								<StyledH3>WYC Constitution</StyledH3>
							</a>
							Every non profit corporation has one
						</li>
						<li>
							<a
								href="https://drive.google.com/file/d/1X4HpwfF9b0arO2AABHElFW4kSikBFNP3/view?usp=sharing"
								target="_blank"
								rel="noreferrer">
								<StyledH3> WYC By-Laws</StyledH3>
							</a>
							The nitty gritty rules of the club
						</li>
						<li>
							<a
								href="https://drive.google.com/file/d/1nv0s-mXHWfgCs0BEJUaSrhan2H40jqxE/view"
								target="_blank"
								rel="noreferrer">
								<StyledH3>WYC Policies</StyledH3>
							</a>
							Some of the more practical information about the club.
						</li>
						<li>
							<a
								href="https://drive.google.com/file/d/138itsSg11eonKlZoJ065LAWgh2DVHFdJ/view?usp=sharing"
								target="_blank"
								rel="noreferrer">
								<StyledH3> Ithaka Policy Document</StyledH3>
							</a>
							Policies governing the use of our flagship, Ithaka.
						</li>
					</StyledList>
				</StyledContainer>
			</div>
			<Footer />
		</>
	);
}
