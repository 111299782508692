// Libraries
import styled from "styled-components";
//Components
import LinkBox from "../Components/LinkBox";

import Footer from "../Components/Footer";
import Header from "../Components/Header";
//Other
import mainPhoto from "../images/headerphoto.jpg";
import community from "../images/community.jpg";
import water from "../images/getWater.jpg";
import involved from "../images/getInvolved.jpg";
import wycLogo from "../images/wyc-logo.svg";

function Home() {
	const StyledPicture = styled.div`
		background: url(${mainPhoto});
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		width: 100%;
		min-height: 20rem;
		margin: 0rem auto;
	`;
	const StyledTextBox = styled.div`
		display: flex;
		margin: 0rem auto;
		align-items: center;
		text-align: center;
		max-width: 48rem;
		font-weight: 600;
		width: 100%;
		height: 100%;
	`;

	return (
		<>
			<Header />
			<div className="grid">
				<StyledPicture className="card-wide" />
				<StyledTextBox className="card-wide">
					<p>
						The Washington Yacht Club is a University of Washington
						student-run sailing club located at the Waterfront Activites
						Center (WAC) on the southeast corner of campus. Come down and
						say hi to make new friends, get on a boat and learn to sail!
					</p>
				</StyledTextBox>

				<LinkBox
					className="card-pic"
					name="Meet the Community"
					backgroundImage={community}
					link="contact"
				/>

				<LinkBox
					className="card-pic"
					name="Get Involved"
					backgroundImage={involved}
					link="join"
				/>

				<LinkBox
					className="card-pic"
					name="Get Boat Ratings"
					backgroundImage={wycLogo}
					link="ratings"
				/>

				<LinkBox
					className="card-pic"
					name="Get on the Water"
					backgroundImage={water}
					link="lessons"
				/>
			</div>
			<Footer />
		</>
	);
}

export default Home;
