//Libraries
import styled from "styled-components";
//Other
import map from "../images/UWMapBig.jpg";

export default function Footer() {
	const StyledContainer = styled.div`
		display: flex;
		width: 90%;
		height: 10rem;
		background-color: #2e0070;
		color: white;
		padding: 1rem;
		margin: 3rem auto 0rem auto;
		font-size: 0.8rem;
	`;
	const StyledPicture = styled.div`
		background: url(${map});
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		width: 90%;
		max-width: 11rem;
	`;
	const StyledContent = styled.div`
		width: 100%;
		height: 100%;
	`;
	const StyledH2 = styled.h2`
		font-size: 1rem;
		font-weight: 700;
		line-height: 0rem;
	`;
	return (
		<StyledContainer>
			<StyledContent>
				<StyledPicture />
			</StyledContent>
			<StyledContent>
				<StyledH2>Office Location</StyledH2>
				Husky Union Building<br></br>
				Sub-Basement, Room 259<br></br>
				<br></br>
				<StyledH2>Office Hours</StyledH2>
				By Appointment
			</StyledContent>
			<StyledContent>
				<StyledH2>WAC Location</StyledH2>
				3710 Montlake Blvd <br></br>
				NE, Seattle, WA 98195<br></br>
				<br></br>
				<StyledH2>Sailing Hours</StyledH2>
				To be Announced
			</StyledContent>
			<StyledContent>
				<StyledH2>Contact</StyledH2>
				Phone: 425-298-5670<br></br>
				Email: sailing@uw.edu<br></br>
				<br></br>
				<StyledH2>Discord Link</StyledH2>
				https://discord.gg/<br></br>
				5uGyJvtXM6
			</StyledContent>
		</StyledContainer>
	);
}
