//Libraries
import styled from "styled-components";
// Other
import logo from "../images/Logo-type.png";
import { Link } from "react-router-dom";

// Add styling found at 2:16 https://www.youtube.com/watch?v=705XCEruZFs
export default function Header() {
	const StyledContainer = styled.nav`
		height: 2.5rem;
		width: 90%;
		background-color: white;
		margin: 0rem auto 1rem auto;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		position: sticky;
		top: 0px;
		z-index: 9999;
	`;

	//the following are not styled components and as such must use reacts awful inline syntax
	const StyledLink = {
		margin: "0 1rem",
		color: "black",
		textDecoration: "none",
	};
	const StyledPicture = {
		background: `url(${logo})`,
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		height: "2rem",
		width: "100%",
		maxWidth: "7rem",
	};

	return (
		<StyledContainer>
			<Link to="/" style={StyledPicture} />
			<Link to="/join" style={StyledLink}>
				Join
			</Link>
			|
			<Link to="/ratings" style={StyledLink}>
				Ratings
			</Link>
			|
			<Link to="/lessons" style={StyledLink}>
				Lessons & Events
			</Link>{" "}
			|
			<Link to="/fleet" style={StyledLink}>
				Our Fleet
			</Link>{" "}
			|
			<Link to="/members" style={StyledLink}>
				For Members
			</Link>{" "}
			|
			<Link to="/contact" style={StyledLink}>
				Contact Us
			</Link>
		</StyledContainer>
	);
}
