// Libraries
import styled from "styled-components";
import { Link } from "react-router-dom";

//Components
import Footer from "../Components/Footer";
import Header from "../Components/Header";

export default function LessonsNEvents() {
	const StyledContainer = styled.div`
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	`;
	// Change This \/
	const StyledSquareTxt = styled.div`
		max-width: 350px;
		min-width: 250px;
	`;
	const StyledTopCont = styled.div`
		display: flex;
		align-items: start;
		flex-direction: row;
		margin: 0 auto;
		flex-wrap: wrap;
	`;

	const StyledIframe = styled.iframe`
		width: 100%;
		height: 100%;
		min-height: 50rem;
	`;
	return (
		<>
			<Header />
			<StyledContainer>
				<h1>Lessons & Events</h1>
			</StyledContainer>
			<div className="grid">
				<StyledTopCont className="card-wide">
					<StyledContainer>
						<h2>Before the start of lesson:</h2>
						<StyledSquareTxt>
							<p>
								<ul>
									<li>
										<Link to="/join">Purchase</Link> club membership
									</li>
									<li>Sign up for lessons with your WYC number</li>
									<li>
										Read the&nbsp;
										<Link to="/lessons/sailguide">sailing guide</Link>
									</li>
								</ul>
							</p>
						</StyledSquareTxt>
					</StyledContainer>
					<StyledContainer>
						<h2>What if lessons are full?</h2>
						<StyledSquareTxt>
							<p>
								<ul>
									<li>
										Add yourself to a waitlist, and email the
										instructor (spots very frequently open up)
									</li>
									<li>
										Come out to&nbsp;
										<Link to="/lessons/events">
											Supervised Sailing
										</Link>
										!
									</li>
								</ul>
							</p>
						</StyledSquareTxt>
					</StyledContainer>
				</StyledTopCont>
				<StyledContainer className="card-wide">
					<h2>Upcoming Lessons & Events</h2>
					<br></br>NOTE: Most of our events are located under the
					"Specialty" tab<br></br>
					<br></br>
					<StyledIframe src="https://database.washingtonyachtclub.org/listles.cgi" />
					<p>
						Checkout a complete list of all the types of events we have
						<button>here</button>
					</p>
				</StyledContainer>
			</div>
			<Footer />
		</>
	);
}
