// Libraries
import styled from "styled-components";
import { Link } from "react-router-dom";

//Components
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import LinkBox from "../Components/LinkBox";
//others
import wycLogo from "../images/wyc-logo.svg";

export default function Ratings() {
	const StyledContainer = styled.div`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
	`;
	const StyledTxtCont = styled.div`
		max-width: 45rem;
	`;

	return (
		<>
			<Header />
			<div className="grid">
				<StyledContainer className="card-wide">
					<h1>How Ratings Work</h1>
					<StyledTxtCont>
						<p>
							We rely on a "Ratings" system to ensure that our members
							are qualified to sail and not damage club equipment or
							themselves. A Rating involves a Practical (on-the-water)
							test and a Written test. When you take a lesson, you will
							test for a rating at the end of the lesson. Most lessons
							are for beginner sailors, so almost everyone gets at least
							a Novice rating. You do not need to take a lesson in order
							to receive a rating. <br></br>
							<br></br>Note: You do not need a rating to sail during
							&nbsp;
							<Link to="/lessons/events">Supervised Sailing Hours</Link>.
							In fact, this is likely when you will receive your ratings.
							<br></br>
							<br></br>Expiration: All Novice ratings, except Keelboat
							Novice, expire 6 months after you received the rating
							(check the back of your membership card). This is to
							encourage members to continue to advance in their ratings.
						</p>
					</StyledTxtCont>
				</StyledContainer>

				<StyledContainer className="card-small">
					<h2>Practical Test</h2>
					<StyledTxtCont>
						<p>
							An Instructor, Rating Examiner, or Chief* tests you by
							watching you set up (rig) and sail a boat in a certain
							amount of wind. To take the test, you can:
							<ul>
								<li>
									Sign up for a&nbsp;<Link to="/contact">lesson</Link>
									&nbsp;and take the test with your Instructor
								</li>
								<li>
									Make an appointment with a Ratings Examiner or Chief
									and come during Supervised Sailing Hours
								</li>
								<li>
									Emailing the&nbsp;
									<a href="mailto:ratings@washingtonyachtclub.org">
										Ratings Examiner
									</a>
								</li>
							</ul>
							*Only Chiefs can administer Skipper Practical tests.
						</p>
					</StyledTxtCont>
				</StyledContainer>

				<StyledContainer className="card-small">
					<h2>Written Test</h2>
					<StyledTxtCont>
						<p>
							There's one test that covers all Novice & Intermediate
							ratings, and another that covers Skipper ratings. The
							written Test is based off the information in the &nbsp;
							<Link to="/lessons/sailguide">sailing guide</Link>. You can
							take the written tests:
							<ul>
								<li>
									During our&nbsp;
									<Link to="/contact">office hours</Link>
								</li>
								<li>During the Supervised Sailing Hours</li>
								<li>With an Officer/Chief</li>
								<li>With any Instructor</li>
							</ul>
						</p>
					</StyledTxtCont>
				</StyledContainer>

				<StyledContainer className="card-wide">
					<h2>How to Interpret the Ratings</h2>
					<StyledTxtCont>
						<p>
							An example of a rating is: "Singlehanded Intermediate." The
							following are some explanation on how to interpret the
							rating:
							<ul>
								<li>
									The first half of the rating indicates what boat you
									were sailing on in that wind. We divide our boats up
									into major categories: "SH/Singlehanded" (one person
									boats), "DH/Double Handed" (two person boats),
									"Catamaran" (boats with two hulls), "Sailboard"
									(Windsurfers), and "Keelboat" (the large boats).
								</li>
								<li>
									The second word of the rating indicates the wind
									level. "Novice" (light wind, less than 7 knots),
									"Intermediate" (some wind, less than 15 knots), and
									"Skipper" (very windy, more than 15 knots).
								</li>
							</ul>
							<b>Note:</b> Keelboats and Windsurfers have separate
							written tests. Also, some of our performance boats are
							complicated to rig, and thus require an additional "rig
							rating."
						</p>
					</StyledTxtCont>
				</StyledContainer>

				<LinkBox
					className="card-pic"
					name="SH Ratings"
					backgroundImage={wycLogo}
					link="/join/ratings/singlehanded"
				/>

				<LinkBox
					className="card-pic"
					name="DH Ratings"
					backgroundImage={wycLogo}
					link="/join/ratings/doublehanded"
				/>

				<LinkBox
					className="card-pic"
					name="Catamaran Ratings"
					backgroundImage={wycLogo}
					link="/join/ratings/catamaranhanded"
				/>

				<LinkBox
					className="card-pic"
					name="Keelboat Ratings"
					backgroundImage={wycLogo}
					link="/join/ratings/keelboathanded"
				/>
			</div>
			<Footer />
		</>
	);
}
