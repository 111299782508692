import React from "react";
import ReacStyledNormDataOM from "react-dom/client";
import "./index.css";
// import reportWebVitals from "./reportWebVitals";

//Router Docs @ https://reactrouter.com/docs/en/v6/getting-started/tutorial
import { BrowserRouter, Routes, Route } from "react-router-dom";

//Pages
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Fleet from "./Pages/Fleet";
import Keelboats from "./Pages/FleetSubpages/Keelboats";
import LessonsNEvents from "./Pages/LessonsNEvents";
import Members from "./Pages/Members";
import Join from "./Pages/Join";
import Ratings from "./Pages/Ratings";
import Template from "./Pages/zDefaultPageTemplate";

const root = ReacStyledNormDataOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/join" element={<Join />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/fleet" element={<Fleet />}></Route>
				<Route path="/fleet/dingies" element={<Template />} />
				<Route path="fleet/daysailors" element={<Template />} />
				<Route path="fleet/flagship" element={<Template />} />
				<Route path="fleet/performancesailing" element={<Template />} />
				<Route path="fleet/keelboats" element={<Keelboats />} />
				<Route path="fleet/windsurfing" element={<Template />} />
				<Route path="/lessons" element={<LessonsNEvents />} />
				<Route path="/members" element={<Members />} />
				<Route path="/ratings" element={<Ratings />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);
