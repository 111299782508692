// Libraries
import styled from "styled-components";
//Components
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Boat from "../../Components/Boat";
//Others
import wycLogo from "../../images/wyc-logo.svg";

export default function Keelboats() {
	const StyledContainer = styled.div`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
	`;

	const StyledTxtCont = styled.div`
		max-width: 45rem;
	`;
	const StyledText = styled.h4`
		text-align: center;
		display: inline;
	`;
	//Table Settings
	const StyledTable = styled.table`
		width: 100%;
		max-width: 70rem;
	`;
	const StyledHeadData = styled.td`
		text-align: left;
		font-weight: 700;
	`;
	const StyledNormData = styled.td`
		text-align: left;
		padding: 0.5rem 0rem;
	`;

	const StyledRow = styled.tr`
		min-height: 3rem;
	`;

	return (
		<>
			<Header />
			<StyledContainer>
				<h1>Keelboats</h1>
			</StyledContainer>
			<div className="grid">
				<Boat
					className="card-wide"
					name="Ranger 26 (Rascal)"
					backgroundImage={wycLogo}
					description="Rascal is our smallest cruising keelboat. She has a masthead sloop rig, sail area of 322 sq ft, fin keel with draft of 4.33 ft, and spade rudder. She displaces 5,860 lbs and has 2,050 lbs of ballast. Rascal is rigged to fly a symmetrical spinnaker. She sleeps 3-4."
					rating="Rascal requires a keelboat novice rating."
					phrf="PHRF 225"></Boat>
				<Boat
					className="card-wide"
					name="Catalina 27 (Charlotte)"
					backgroundImage={wycLogo}
					description="Charlotte is next largest cruising keelboats. She has a masthead sloop rig, sail area of 346 sq ft, fin keel with draft of 4.00 ft, and spade rudder. She displaces 6,850 lbs and has 2,700 lbs of ballast. Charlotte is rigged to fly a symmetrical spinnaker. She sleeps 4-5."
					rating="Charlotte requires a keelboat novice rating."
					phrf="PHRF 227"></Boat>
				<Boat
					className="card-wide"
					name="Catalina 27 (Deception)"
					backgroundImage={wycLogo}
					description="Deception is nearly identical to Charlotte. She has a masthead sloop rig, sail area of 346 sq ft, fin keel with draft of 4.00 ft, and spade rudder. She displaces 6,850 lbs and has 2,700 lbs of ballast. Deception is rigged to fly a symmetrical spinnaker. She sleeps 4-5."
					rating="Deception requires a keelboat novice rating."
					phrf="PHRF 227"></Boat>
				<Boat
					className="card-wide"
					name="Cal 3-30 (Nightshade)"
					backgroundImage={wycLogo}
					description="Nightshade is one of our two 30 ft, inboard diesel keelboats. She also has a masthead sloop rig, sail area of 474 sq ft, fin keel with draft of 5.50 ft , and spade rudder. She displaces 10,500 lbs and has 5,000 lbs of ballast. Nightshade is rigged to fly a symmetrical spinnaker. She sleeps 4-6."
					rating="Nightshade requires a keelboat skipper rating and additional rig rating. More info can be found here."
					phrf="PHRF 177"></Boat>
				<Boat
					className="card-wide"
					name="San Juan 30 (Talaria III)"
					backgroundImage={wycLogo}
					description="In early 2017, WYC welcomed Talaria III, the second of our 30 ft, inboard diesel keelboats. She has a masthead sloop rig, sail area of 402 sq ft, fin keel with draft of 5.33 ft, and spade rudder. She displaces 7200 lbs and has 3550 lbs of ballast. Talaria III is rigged to fly a symmetrical spinnaker. She is the outfitted the tall rig option and is set up as a racer/cruiser. She sleeps 4-5."
					rating="Talaria III requires a keelboat skipper rating and additional rig rating."
					phrf="PHRF 168"></Boat>
				<StyledContainer className="card-wide">
					<h2>Pumpout Stations</h2>
					<StyledTxtCont>
						Emptying a holding tank is a necessary responsibility for
						keelboat sailors. Seriously, if you or your crew used the
						head? Pump it out. <b>Not optional.</b> <br></br>
						<br></br>The most convenient locations for doing this are:
						from the sound- Shilshole Marina; from Lake Washington-
						Carrillon Point Marina; from Lake Union- Boat Street Marina.
						Check out&nbsp;
						<a
							href="https://www.google.com/maps/d/u/0/edit?mid=1BOXpE543-yZFM6VNIHEq3ukbgwY&msa=0&ll=47.63806897858727%2C-122.30552150000001&z=11"
							target="_blank"
							rel="noreferrer">
							this map
						</a>
						&nbsp;to see locations. Rumor has it there is also the Marina
						Mart 24 Hour Pumpout about 3/4 of the way down the west side
						of Lake Union.
					</StyledTxtCont>
				</StyledContainer>
				<StyledContainer className="card-wide">
					<h2>Keelboat Reservations</h2>
					<StyledTxtCont>
						The club currently has 5 keelboats. Members & associate
						members can reserve a keelboat in 12 hour increments via the
						keelboat abailability portal.
						<button>Link to portal tba...</button>
						<br></br>
						<br></br>
					</StyledTxtCont>
					<h3>Reservation Rates</h3>
					<StyledTable>
						<StyledRow>
							<td colspan="2">&nbsp;</td>
							<td colspan="2" style={{ verticalAlign: "top" }}>
								<StyledText>Student Members</StyledText>
							</td>
							<td colspan="2">
								<StyledText>Associate Members </StyledText>
								<br></br>
								(Faculty, Staff, Alumni Association, Spouses)
							</td>
						</StyledRow>
						<StyledRow>
							<StyledHeadData></StyledHeadData>
							<StyledHeadData></StyledHeadData>
							<StyledHeadData>Weekdays</StyledHeadData>
							<StyledHeadData>Weekends / Holidays</StyledHeadData>
							<StyledHeadData>Weekdays</StyledHeadData>
							<StyledHeadData>Weekends / Holidays</StyledHeadData>
						</StyledRow>
						<StyledRow>
							<StyledNormData></StyledNormData>
							<StyledHeadData>Full Day (outboard) </StyledHeadData>
							<StyledNormData>$22</StyledNormData>
							<StyledNormData>$30</StyledNormData>
							<StyledNormData>$35</StyledNormData>
							<StyledNormData>$45</StyledNormData>
						</StyledRow>
						<StyledRow>
							<StyledNormData></StyledNormData>
							<StyledHeadData>Half Day* (outboard) </StyledHeadData>
							<StyledNormData>$16</StyledNormData>
							<StyledNormData>$20</StyledNormData>
							<StyledNormData>$25</StyledNormData>
							<StyledNormData>$30</StyledNormData>
						</StyledRow>
						<StyledRow>
							<StyledNormData></StyledNormData>
							<StyledHeadData>Full Day (inboard) </StyledHeadData>
							<StyledNormData>$25</StyledNormData>
							<StyledNormData>$33</StyledNormData>
							<StyledNormData>$40</StyledNormData>
							<StyledNormData>$50</StyledNormData>
						</StyledRow>
						<StyledRow>
							<StyledNormData></StyledNormData>
							<StyledHeadData>Half Day* (inboard) </StyledHeadData>
							<StyledNormData>$19</StyledNormData>
							<StyledNormData>$23</StyledNormData>
							<StyledNormData>$30</StyledNormData>
							<StyledNormData>$35</StyledNormData>
						</StyledRow>
					</StyledTable>
				</StyledContainer>
				<StyledContainer className="card-wide">
					<h3>Online Keelboat Payment Procedures</h3>
					<StyledTxtCont>
						The sail locker has gone cashless! This means that all keel
						boat reservations must be purchased online. If you would like
						to reserve a keel boat, please do the following:
						<ul>
							<li>
								After checking that a boat is available for the intended
								reservation period, purchase the appropriate number of
								keel boat reservations from the&nbsp;
								<a
									href="https://washington-yacht-club.square.site/"
									target="_blank"
									rel="noreferrer">
									square store
								</a>
								.<br></br>
								<br></br>
							</li>
							<li>
								Go to the keel boat calendar and reserve your vessel,
								but be sure to enter the confirmation number associated
								with your square purchase as a comment on the
								reservation! Any reservations without a confirmation
								number linking it to the appropriate payment will be
								deleted without a refund
							</li>
						</ul>
					</StyledTxtCont>
				</StyledContainer>
			</div>
			<Footer />
		</>
	);
}
