// Libraries
import styled from "styled-components";
//Components
import Footer from "../Components/Footer";
import Header from "../Components/Header";

export default function Template() {
	const StyledContainer = styled.div`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
	`;

	return (
		<>
			<Header />
			<StyledContainer>
				<h1>This Page Is Coming Soon!</h1>
			</StyledContainer>
			<div className="grid"></div>
			<Footer />
		</>
	);
}
