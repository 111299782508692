// Libraries
import styled from "styled-components";
import { Link } from "react-router-dom";

//Components
import Footer from "../Components/Footer";
import Header from "../Components/Header";
//Others
import map from "../images/UWMapBig.jpg";

export default function Join() {
	const StyledContainer = styled.div`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
	`;
	const StyledPicture = styled.div`
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;

		background: url(${map});
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		height: 100%;
		width: 90%;
		min-height: 15rem;
	`;
	const StyledTxtCont = styled.div`
		max-width: 60rem;
	`;

	//Table Settings
	const StyledTable = styled.table`
		width: 100%;
		max-width: 70rem;
	`;
	const StyledHeadData = styled.td`
		text-align: left;
		font-weight: 700;
	`;
	const StyledNormData = styled.td`
		text-align: left;
		padding: 0.5rem 0rem;
	`;

	const StyledRow = styled.tr`
		min-height: 3rem;
	`;

	return (
		<>
			<Header />
			<div className="grid grid3">
				<StyledContainer className="card-wide3">
					<h1>Joining The Club</h1>
					<StyledTxtCont>
						<b>For Students & Current Non-Student Members:</b> We are
						currently operating at reduced capacity and are only accepting
						new sign-ups for student memberships at this time. Please fill
						out the&nbsp;
						<a
							href="https://forms.gle/ogBDayPTLdrcQ7F16"
							target="_blank"
							rel="noreferrer">
							Summer 2022 Membership Form
						</a>
						&nbsp;if you are interested in joining the club (students
						only) or renewing your membership (students & non-students).
						WAC waterfront access fees are waived for full-time Seattle
						campus students that pay a Services & Activities Fee (SAF) as
						part of their tuition. Students that are enrolled part-time or
						attend UW Bothell or UW Tacoma do not qualify for this waiver.
						Non-students who do not have a full IMA membership must pay a
						$50 waterfront access fee that goes to UW Recreation.<br></br>
						<br></br>
						<b>For non-Students Interested in Joining the Club:</b> Due to
						University restrictions, we are not currently able to accept
						new non-student member sign-ups except in cases where the
						individual is able to help provide volunteer sailing
						instruction for student members. We apologize for these
						policies outside of our control.
						<br></br>
						<br></br>
						If you do wish to join our non-student wait list, you can fill
						out the non-student wait list survey, but we have no expected
						time frame for being able to move people off the&nbsp;
						<a
							href="https://docs.google.com/forms/d/e/1FAIpQLSduOtSAn_IsDFMLSUfRaFduAmLCW-hDE72tGJ1e_n6lwXVvtg/viewform"
							target="_blank"
							rel="noreferrer">
							wait list
						</a>
						. For more information, please contact the Program Director.
						<br></br>
						<br></br>
						As a student organization, we cannot exceed a ratio of two
						students for every one non-student member. Most available
						non-student memberships go to graduating students who have
						taught lessons for at least two years as students.
					</StyledTxtCont>
				</StyledContainer>
				<StyledContainer>
					<h2>Member Benefits</h2>
					<StyledTxtCont>
						<ul>
							<li>
								Go sailing on your own or with friends during&nbsp;
								<Link to="/lessons/events">supervised sailing</Link>
								&nbsp;
							</li>
							<li>
								Dinghy, catamaran, and daysailer checkout with no
								additional reservation fees
							</li>
							<li>
								<Link to="/fleet/keelboats">Keelboat checkouts</Link>
								&nbsp;(appropriate ratings required)
							</li>
							<li>
								<Link to="/lessons">Sailing Lessons</Link>
							</li>
							<li>
								<Link to="/lessons/events">Social Events</Link>
							</li>
							<li>Member discount at Fisheries Supply in Wallingford</li>
						</ul>
					</StyledTxtCont>
				</StyledContainer>
				<StyledContainer>
					<h2>Prices</h2>
					<StyledTable maxWidth="30rem">
						<StyledRow>
							<th></th>
							<StyledHeadData>Initiation Fee</StyledHeadData>
							<StyledHeadData>Quarterly Dues</StyledHeadData>
						</StyledRow>
						<StyledRow>
							<StyledHeadData> Students*: </StyledHeadData>
							<StyledNormData>$0</StyledNormData>
							<StyledNormData>$29</StyledNormData>
						</StyledRow>
						<StyledRow>
							<StyledHeadData> Faculty/Staff*: </StyledHeadData>
							<StyledNormData>$45</StyledNormData>
							<StyledNormData>$80+$50 WAC Fee</StyledNormData>
						</StyledRow>
						<StyledRow>
							<StyledHeadData> Alumni*: </StyledHeadData>
							<StyledNormData>$45</StyledNormData>
							<StyledNormData>$80+$50 WAC Fee</StyledNormData>
						</StyledRow>
					</StyledTable>
					*WYC does not give refunds on membershiip fees and dues
				</StyledContainer>
				<StyledContainer className="card-end3 grid-pic">
					<h2>Map of Locations</h2>
					<StyledPicture />
				</StyledContainer>
			</div>
			<div className="grid">
				<StyledContainer className="card-wide">
					<h1>How To Get Involved</h1>
					<StyledTxtCont>
						We are a student run club here on campus, so all help is
						welcome. Even if you're brand new to our club we'd love your
						help. All of our instructors, officers, and most committee
						members get free membership!
					</StyledTxtCont>
				</StyledContainer>
				<StyledContainer>
					<h2>Sailing</h2>
					<StyledTxtCont>
						Sailing in the main purpose of the club! We help all members
						learn what is takes to take any of out boats out via&nbsp;
						<Link to="/contact">lessons</Link>&nbsp;and our &nbsp;
						<Link to="/contact">ratings</Link>&nbsp;system. Any member can
						sign up for any of our events (like supervised sailing) or
						lessons to get on a boat. If no lessons and events are posted
						go to our&nbsp;
						<a
							href="https://discord.gg/5uGyJvtXM6"
							target="_blank"
							rel="noreferrer">
							discord
						</a>
						&nbsp;and send a message in the "crew_discovery" channel. Once
						you are rated you can start taking boats out by yourself.
					</StyledTxtCont>
				</StyledContainer>
				<StyledContainer>
					<h2>Teaching</h2>
					<StyledTxtCont>
						Instructors get a free membership the quarter that they are
						teaching. You only need an Intermediate rating to teach, and
						anyone can become an Assistant Instructors. Contact the&nbsp;
						<a href="mailto:vicecommodore@washingtonyachtclub.org">
							vice commodore
						</a>
						, to let them know you are interested.
					</StyledTxtCont>
				</StyledContainer>
				<StyledContainer>
					<h2>Work Parties</h2>
					<StyledTxtCont>
						Every week we have regular work parties to keep our fleets in
						good running order, eat some BBQ, and socialize. Even if you
						don't know anything about boat maintenance, we encourage all
						new members to come to work parties. You can learn how to do a
						lot, from simple sail repairs to extensive fiberglassing, and
						there's always something to do for all skill levels.
					</StyledTxtCont>
				</StyledContainer>
				<StyledContainer>
					<h2>Club Meetings</h2>
					<StyledTxtCont>
						Meetings are usually held every 2 weeks. At the meeting we
						discuss where the club is going, you can propose changes,
						voice your opinion, and all student members can vote!
					</StyledTxtCont>
				</StyledContainer>

				<StyledContainer className="card-med">
					<h2>Chiefs & Officers</h2>
					<StyledTxtCont>
						Chiefs are members who are knowledgeable sailors and are
						passionate about the club. Both students and Alumni are
						chiefs. They enforce club rules and hold authority in all
						matters regarding the safety of all club operations.
						<br></br>
						<br></br>We also have 3 main officers:
						<ul>
							<li>Commodore (in charge of the club as a whole)</li>
							<li>Vice Commodore (in charge of teaching)</li>
							<li>Rear Commodore (in charge of social events)</li>
						</ul>
						We always welcome new students who are interested in becoming
						a chief or an officer. Check out the&nbsp;
						<Link to="/contact">list</Link>&nbsp;of our current officers,
						chiefs, and how to contact them!
					</StyledTxtCont>
				</StyledContainer>
				<StyledContainer className="card-med">
					<h2>Committee Members</h2>
					<StyledTxtCont>
						Don't want to be an officer but still want to help? As a
						student club, we rely completely on volunteers, but as a
						'Thank-You', we give free memberships to position holders in
						our "Committees." If you're excited about teaching, boat
						maintenance, or helping out with the administrative side of
						our club (e.g. event planning, publicity, etc), look below for
						a description of positions.&nbsp;
						<Link to="/contact">Contact an officer</Link>, or just come to
						a meeting, if you are interested in a position.
						<br></br>
						<br></br>
						<StyledContainer>
							<StyledTable>
								<StyledRow>
									<StyledHeadData>Fleet Captains </StyledHeadData>
									<StyledNormData>
										There are ten different fleet captains and a Head
										Fleet Captain. Each fleet captain coordinates the
										maintenance of their assigned boats type and all
										fleet captains report to the Head Fleet Captain.
									</StyledNormData>
								</StyledRow>
								<StyledRow>
									<StyledHeadData>Publicity Chair </StyledHeadData>
									<StyledNormData>
										Advertises for the club. Past work includes
										designing posters, t-shirts, sweatshirts, TV ads,
										and media interviews.
									</StyledNormData>
								</StyledRow>
								<StyledRow>
									<StyledHeadData> Program Director </StyledHeadData>
									<StyledNormData>
										Oversees the day-to-day operations of the club.
										Items include processing memberships, taking
										meeting notes, handling some club purchases, and
										setting meeting agendas. This is a paid position.
									</StyledNormData>
								</StyledRow>
								<StyledRow>
									<StyledHeadData>
										Social Committee Chair
									</StyledHeadData>
									<StyledNormData>
										Organizes informal and formal club events.
									</StyledNormData>
								</StyledRow>
								<StyledRow>
									<StyledHeadData> SNC Chair</StyledHeadData>
									<StyledNormData>
										Coordinates the Quarterly
										<Link to="/lessons/events">Snooze n' Cruise</Link>
										&nbsp; event.
									</StyledNormData>
								</StyledRow>
								<StyledRow>
									<StyledHeadData>Quartermaster</StyledHeadData>
									<StyledNormData>
										Identifies parts and raw materials needed to
										repair boats and coordinates acquisition of those
										components.
									</StyledNormData>
								</StyledRow>
								<StyledRow>
									<StyledHeadData>Purser </StyledHeadData>
									<StyledNormData>
										Takes care of the Yacht Club's taxes and payroll.
										This is a paid position.
									</StyledNormData>
								</StyledRow>
								<StyledRow>
									<StyledHeadData> Media Curator </StyledHeadData>
									<StyledNormData>
										Writes our newsletter and maintains the club's
										library and photos.
									</StyledNormData>
								</StyledRow>
								<StyledRow>
									<StyledHeadData> Ratings Examiners </StyledHeadData>
									<StyledNormData>
										Tasked with hosting&nbsp;
										<Link to="/lessons/events">
											Supervised Sailing Hours
										</Link>
										, teaching club lessons, and administering
										certification exams.
									</StyledNormData>
								</StyledRow>
								<StyledRow>
									<StyledHeadData>Webmaster</StyledHeadData>
									<StyledNormData>
										Maintains or completely rebuilds the club website.
									</StyledNormData>
								</StyledRow>
								<StyledRow>
									<StyledHeadData> Faculty Adviser </StyledHeadData>
									<StyledNormData>
										Oversees the relationship between the University
										and the club.
									</StyledNormData>
								</StyledRow>
							</StyledTable>
						</StyledContainer>
					</StyledTxtCont>
				</StyledContainer>
			</div>
			<Footer />
		</>
	);
}
