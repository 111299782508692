// Libraries
import styled from "styled-components";
import { Link } from "react-router-dom";

//Components
import LinkBox from "../Components/LinkBox";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
//others
import wycLogo from "../images/wyc-logo.svg";
import dinghy from "../images/fleet/Laser1.jpg";
import daySailor from "../images/fleet/Ensign-1.jpg";
import flagship from "../images/fleet/Ithaka-2.jpg";
import keelboat from "../images/fleet/Catalina1.jpg";
import performance from "../images/fleet/505-1.jpg";
import windSurf from "../images/fleet/WindSurf-1.jpg";

export default function Fleet() {
	const StyledContainer = styled.div`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
	`;
	const StyledTxtCont = styled.div`
		max-width: 45rem;
	`;

	return (
		<>
			<Header />
			<div className="grid grid3">
				<StyledContainer className="card-wide3">
					<h1>Our Fleet</h1>
					<StyledTxtCont>
						Our club has a wide range of boats & boards from windsurfers
						to our flagship boat a Swan 441 (Ithaka). We encourage all of
						our members to begin learning to sail on Dinghies, which are
						small 1-2 person boats. To find more information about each
						type of vessel our club has, use the links below.
						<br></br>
						<br></br>
						In addition to&nbsp;
						<Link to="/lessons/events">supervised sailing</Link>
						&nbsp;hours and&nbsp;
						<Link to="/lessons">lessons</Link>, we rely on a "ratings"
						system to ensure that our members are qualified to sail and
						not damage club equipment / themselves. To learn more about
						which ratings are required to sail our boats, visit the&nbsp;
						<Link to="/ratings">ratings page</Link>.<br></br>
						<br></br>
						If you have recently been out sailing and see something that
						needs to be fixed, please fill out a boat maintenance request
						form below.&nbsp;
						<a
							href="https://forms.gle/8vFbZ9EFuBrDcoLh8"
							target="_blank"
							rel="noreferrer">
							<button>Maintenance Request</button>
						</a>
					</StyledTxtCont>
				</StyledContainer>
				<LinkBox
					className="card-pic"
					name="Dingies"
					backgroundImage={dinghy}
					link="/fleet/dingies"
				/>
				<LinkBox
					className="card-pic"
					name="Day Sailors"
					backgroundImage={daySailor}
					link="/fleet/daysailors"
				/>
				<LinkBox
					className="card-pic"
					name="Flagship"
					backgroundImage={flagship}
					link="/fleet/flagship"
				/>
				<LinkBox
					className="card-pic"
					name="Performance Sailing"
					backgroundImage={performance}
					link="/fleet/performancesailing"
				/>
				<LinkBox
					className="card-pic"
					name="Wind Surfing"
					backgroundImage={windSurf}
					link="/fleet/windsurfing"
				/>
				<LinkBox
					className="card-pic"
					name="Keelboats"
					backgroundImage={keelboat}
					link="/fleet/keelboats"
				/>
			</div>
			<Footer />
		</>
	);
}
